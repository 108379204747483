.favirote-box {
	float: right;
	width: 91.666666666667%;
}

.favirote-box .section {
	display: block;
	font-size: 1.2em;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
}

.favirote-box a {
	text-decoration: none;
	transition: .2s;
}

.favirote-box .row {
	position: relative;
}

.section {
	background: #fff;
	margin-bottom: 15px;
	border-radius: 10px;
}

.section .section-name {
	padding: 5px 5px;
	border-bottom: 2px solid #f1f4f9;
	line-height: 16px;
}

.section .section-name strong {
	color: #3c3c3c;
	font-weight: bold;
	font-size: 16px;
	position: relative;
}

.section .items {
	padding: 5px 10px;
}

.row {
	margin-left: -10px;
	margin-right: -10px;
	*zoom: 1;
}

.row:before, .row:after {
	content: "";
	display: table;
}

.row:after {
	clear: both;
}

.section .item a {
	display: block;
	/* width: 100%; */
	padding: 10px 1%;
	box-sizing: border-box;
	transition: none;
	border-radius: 6px;
}

.section .item a:hover {
	background-color: #f2f8fd;
}

.section .item a img {
	float: left;
	width: 30px;
	height: 24px;
	border-radius: 100%;
}

.section .item a .item-name {
	margin-left: 25px;
	color: #3c3c3c;
	font-size: 15px;
	white-space: nowrap;
	height: 20px;
	line-height: 21px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.section .item a:hover .item-name {
	color: #ee521c;
	text-decoration: underline;
}

.item {
	float: left;
	// width: auto; 
	width: 140px; 
	padding-left: 5px;
	padding-right: 5px;
	margin-left: -10px;
	margin-right: 6px;
}

.item-name {
	padding: 2px 5px;
}
